import React from 'react'
import Img from 'gatsby-image'
import { graphql, StaticQuery, Link } from 'gatsby'
import PortableText from '../portableText'
import TrainingPage from '../../templates/training-page'
import BackgroundImage from 'gatsby-background-image'

export const ColumnSection = ({ columns = [] }) => {
    return (
        <section className="bg-white even:bg-gray-50 py-16">
            <div className="max-w-6xl mx-auto lg:px-0 px-4 grid gap-8" style={{ gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))" }}>
                {
                    columns.map(({ title, body }) => {
                        return (
                            <div key={title} className="leading-8 text-lg text-gray-600 ">
                                {title && <h3 className="font-roboto-slab text-3xl text-gray-900">{title}</h3>}
                                {body && <div className="mt-4"><PortableText blocks={body} /></div>}
                            </div>
                        )
                    })
                }
            </div>
        </section>
    )
}
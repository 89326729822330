import React from 'react'
import Img from 'gatsby-image'
import { graphql, StaticQuery, Link } from 'gatsby'
import PortableText from '../portableText'
import TrainingPage from '../../templates/training-page'
import BackgroundImage from 'gatsby-background-image'

export const ReviewSection = ({ reviews = [], _rawReviews = [], _rawServices = [], reviewVideoUrl = "", hideAllTrainingsButton = false }) => {

    return (
        <>
            {((reviews && reviews.length > 0) || (_rawServices && _rawServices.length > 0)) && <section className="bg-white even:bg-gray-50 py-16">
                <div className="max-w-6xl mx-auto md:px-0 px-4">
                    <div className="grid grid-row-2 md:grid-cols-2 gap-4 px-0 md:px-4">
                        {(_rawServices && _rawServices.length > 0) && <div className="col-span-1 text-lg leading-8">
                            <h3 className="font-roboto-slab text-3xl">Meest gekozen behandelingen</h3>
                            <div className="mt-4 text-gray-600 ">
                                <PortableText blocks={_rawServices} />
                            </div>
                            <span className="block sm:hidden mt-4 text-gray-600">Op mobiele telefoons werkt de 360-functionaliteit niet. <a className="font-roboto-slab text-medium text-orange-400" target="_blank" href={"https://www.youtube.com/watch?v=" + (reviewVideoUrl || "")}>Ga naar de Youtube-app om deze functionaliteit te ervaren. &rarr;</a></span>
                        </div>}
                        { reviewVideoUrl && <div className="mt-8 md:mt-0 h-64">
                            <div className="relative w-full h-full">
                                <iframe loading="lazy" src={"https://www.youtube.com/embed/" + (reviewVideoUrl || "")} className="absolute top-0 left-0 w-full h-full" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>}
                    </div>
                    {
                        reviews.map(({ reviewer, training: trainingPage = {} }) => {

                            const { slug = {}, bannerImage = {}, training = {}, hideOverlay = false } = trainingPage

                            const { review = [] } = _rawReviews.find(({ reviewer: _reviewer }) => {
                                return reviewer === _reviewer
                            }) || {}

                            const { asset = {} } = bannerImage || {}
                            const { fluid = {}, url } = asset
                            const { current } = slug
                            const { name, description } = training

                            let imageStack = []

                            if(!hideOverlay){
                                imageStack.push("linear-gradient(rgba(0, 0, 40, 0.4), rgba(0, 0, 40, 0.4))")
                            }

                            imageStack.push(fluid)

                            return (
                                <div className="mt-16 md:flex even:flex-row flex-row-reverse ">
                                    <div className="flex-1 md:mx-4 rounded-lg overflow-hidden">
                                        <Link to={current} className="block h-full w-full rounded-lg">
                                            {/* <Img fluid={fluid} className="rounded-lg shadow-lg object-cover" /> */}
                                            <BackgroundImage
                                                Tag="div"
                                                fluid={imageStack}
                                                style={{
                                                    backgroundSize: "cover",
                                                    backgroundPosition: "center center"
                                                }}
                                                className={"flex flex-col justify-center items-center py-8 px-4 rounded-lg overflow-hidden"}
                                            >
                                                <div>
                                                    <h3 className="font-roboto-slab text-white text-2xl uppercase text-center">{name}</h3>
                                                    <p className="pt-8 mt-8 border-t border-orange-400 text-white uppercase text-center leading-8 h-24">{description}</p>
                                                    <span className="block mx-auto mt-8 px-8 py-2 text-orange-400 text-white text-center font-semibold rounded uppercase tracking-widest">
                                                        Lees meer &rarr;</span>
                                                </div>
                                            </BackgroundImage>
                                        </Link>
                                    </div>
                                    <div className="mt-8 md:mt-0 md:mx-4 flex-2">
                                        <h4 className="font-roboto-slab text-2xl text-gray-700">Review over <Link to={current} className="text-orange-400">{name}</Link></h4>
                                        <div className="mt-4 sm:ml-8 sm:pl-8 italic sm:border-l-4 border-orange-300 text-gray-800 text-lg leading-loose">
                                            <PortableText blocks={review} />
                                        </div>
                                        <div className="font-roboto-slab mt-4 text-xl text-orange-400 font-semibold text-right">- {reviewer}</div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    { !hideAllTrainingsButton && <Link to="/behandelingen" className="block mt-8 text-orange-400 text-lg sm:text-2xl font-roboto-slab">Meer informatie over al onze behandelingen &rarr;</Link>}
                </div>
            </section>
            }
        </>
    )
}

// const reviewSectionQuery = graphql`
//     query reviewSectionQuery {
//         page: sanityHomePage {
//             _rawServices,
//             _rawReviews,
//             reviews {
//                 reviewer
//                 training {
//                     training {
//                         name
//                         description
//                     }
//                     slug { 
//                         current
//                     }
//                     bannerImage {
//                         asset {
//                             fluid {
//                                 ...GatsbySanityImageFluid
//                             }
//                             url
//                         }
//                     }
//                 }
//             }
//         }
//     }
// `

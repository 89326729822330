import React from 'react'
import { graphql, Link } from 'gatsby'
import {
	mapEdgesToNodes,
	filterOutDocsWithoutSlugs,
	filterOutDocsPublishedInTheFuture
} from '../lib/helpers'
import BlogPostPreviewList from '../components/blog-post-preview-list'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'

import BackgroundImage from 'gatsby-background-image'
import { ContactSection } from '../components/sections/contact-section'
import { ColumnSection } from '../components/sections/column-section'
import { PeopleIntroductionSection } from '../components/sections/people-introduction-section'
import { ReviewSection } from '../components/sections/review-section'
import { TrainingCarouselSection } from '../components/sections/training-carousel-section'
import { FearCard } from '../components/cards/fearCard'
import { FaqSection } from '../components/sections/faq-section'

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query IndexPageQuery {
	homepage: sanityHomePage {
		id
        slug {
          current
		}
		_rawOpener
        showBanner
        seoTitle
        seoKeywords
        seoDescription
        bannerTitle
		bannerSubTitle
		hideOverlay
        bannerImage {
          asset {
			fluid {
				...GatsbySanityImageFluid
			}
            url
          }
        }
        bannerCta {
          label
          url
		}
		_rawServices
		_rawReviews
		reviews {
			reviewer
			training {
				hideOverlay
				training {
					name
					description
				}
				slug { 
					current
				}
				bannerImage {
					asset {
						fluid {
							...GatsbySanityImageFluid
						}
						url
					}
				}
			}
		}
		reviewVideoUrl
		fearPages {
			fearPage {
				id,
				bannerImage {
					asset {
					fluid {
						...GatsbySanityImageFluid
					}
					url
					}
				}
				slug {
					current
				}
				fear {
					id
					name
					description
				}
			}
		}
	}
  }
`

const IndexPage = ({ data = {}, errors }) => {

	if (errors) {
		return (
			<Layout page={data.homepage || {}}>
				<GraphQLErrorList errors={errors} />
			</Layout>
		)
	}

	const homepage = data.homepage || {}
	const fearPages = homepage.fearPages || []
	const trainingPages = (data.trainingPages || {}).edges || []

	const { _rawOpener = [], _rawReviews = [], _rawServices = [], reviews = [], reviewVideoUrl } = data.homepage || {}

	return (
		<Layout page={data.homepage}>
			<Container>
				<ColumnSection columns={_rawOpener} />
				<section className="bg-white even:bg-gray-50 px-4 4xl:px-0 py-16">
					<div className="max-w-6xl mx-auto">
						<h3 className="font-roboto-slab text-3xl text-gray-900">Bij welke angst kunnen wij je helpen?</h3>
						{/* <div className="block flex flex-col md:flex-row justify-around mt-8"> */}
						<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-8">

							{
								fearPages.map(({ fearPage: node }) => {

									return (
										<FearCard node={node} />
									)
								})
							}

						</div>
						<Link to="/angsten" className="block mt-8 text-orange-400 text-lg sm:text-2xl font-roboto-slab">Alle angsten bekijken &rarr;</Link>
					</div>
				</section>
				{/* <TrainingCarouselSection /> */}
				<ReviewSection _rawReviews={_rawReviews} _rawServices={_rawServices} reviews={reviews} reviewVideoUrl={reviewVideoUrl} />
				<PeopleIntroductionSection />
				<FaqSection />
				<ContactSection />
			</Container>
		</Layout>
	)
}

export default IndexPage

import React from 'react'
import Img from 'gatsby-image'
import { graphql, StaticQuery } from 'gatsby'
import PortableText from '../portableText'

export const PeopleIntroductionSection = () => {

    return (
        <StaticQuery
            query={peopleIntroductionQuery}
            render={({ homepage }) => {

                const therapists = homepage.therapists || []

                if (therapists.length === 0) {
                    return (<></>)
                }

                return (
                    <section className="bg-white even:bg-gray-50 py-16">
                        <div className="max-w-6xl mx-auto px-4 md:px-0">
                            <h3 className="font-roboto-slab text-3xl text-gray-900">De mensen achter Angsthelden</h3>
                            <p className="mt-3 text-lg leading-6 leading-8 tracking-wide text-gray-600">Wij zijn Niels Polak en Giel Dietzenbacher, de oprichters van Angsthelden. Door de combinatie van praktijkervaring, therapeutische ervaring en gebruik van de nieuwste technieken, hebben wij een manier gevonden om angsten te overwinnen.</p>
                            <div className="mt-16 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-12">

                                {
                                    therapists.map(({ therapist: node }) => {
                                        const { profilePhoto, firstName, lastName, _rawBio, function: title } = node
                                        const { asset = {} } = profilePhoto || {}
                                        const { fluid = {} } = asset

                                        return (
                                            <div>
                                                <div className="flex flex-col md:flex-row">
                                                    <div className="h-28 w-28">
                                                        <Img className="h-28 w-28 object-cover rounded-full shadow-lg" fluid={fluid} />
                                                    </div>
                                                    <div className="mt-4 md:mt-0 md:ml-4 flex flex-col justify-center">
                                                        <div className="font-roboto-slab text-2xl text-gray-700">{firstName + " " + lastName}</div>
                                                        <div className="font-roboto-slab text-base text-orange-400">{title}</div>
                                                    </div>
                                                </div>
                                                <div className="mt-4 text-gray-700 tracking-wide leading-loose">
                                                    <PortableText blocks={_rawBio} />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </section>
                )
            }}
        />
    )
}

// allSanityTherapist(filter: { id: { in: ["8ce1fd24-fdad-5509-8130-5aa9f7fa1533", "4fc392c9-4036-56b1-b205-732b07c06e53", "b71ed353-5314-5ec8-981b-02d4b8a6c8be"] } })

const peopleIntroductionQuery = graphql`
query peopleIntroductionQuery {
    homepage: sanityHomePage {
        therapists {
            therapist {
              id
              lastName
              firstName
              profilePhoto {
                asset {
                    fluid {
                        ...GatsbySanityImageFluid
                    }
                }
              }
              function
              _rawBio
            }
          }
    }
}
`